<div class="container-fluid">
  <!-- start page title -->
  <div class="row">
      <div class="col-12">
          <div class="page-title-box d-flex align-items-center justify-content-between">
              <h4 class="mb-0 font-size-18 head-four">Dashboard</h4>
              <div class="page-title-right">
                  <ol class="breadcrumb m-0">
                      <li class="breadcrumb-item active text-primary">Welcome to Dashboard</li>
                  </ol>
              </div>
          </div>
      </div>
  </div>
  <!-- end page title -->
    <div class="row pt-5">
      <div class="col-lg-12 text-center">
        <div class="home-wrapper">
          <div class="mb-5">
            <p class="h1 heading-dark">Security Knowledge Framework</p>
          </div>
          <div class="container">
            <p class="intro">
              <a href="https://owasp.org/www-project-security-knowledge-framework/">OWASP-SKF</a> is an open source web application that explains secure coding principles in multiple programming languages. The goal of OWASP-SKF is to help you learn and integrate security by design in your software development and build applications that are secure by design. OWASP-SKF does this through manageable software development projects with checklists (using <a href="https://owasp.org/www-project-application-security-verification-standard/">OWASP-ASVS</a>/<a href="https://owasp.org/www-project-mobile-security-testing-guide/">OWASP-MASVS</a>  or custom security checklists) and labs to practise security verification (using SKF-Labs, <a href="https://owasp.org/www-project-juice-shop/">OWASP Juice-shop</a>, and best practice code examples from SKF and the <a href="https://cheatsheetseries.owasp.org">OWASP-Cheatsheets</a>).

              If you want a simple set of courses on the fundamentals of developing secure software that don’t involve programming exercises, you might instead consider the <a href="https://openssf.org/edx-courses/">Secure Software Development Fundamentals Courses available on edX from OpenSSF</a>.
            </p>
          </div>
          <div class="py-4">
            <button (click)="tour()" class="btn btn-primary btn-lg">Start tour</button>
           </div>
           
          <div class="row justify-content-center">
            <div class="col-md-3 d-flex align-items-stretch">
              <div class="card mt-4 maintenance-box">
                <div class="card-body d-flex flex-column">
                  <i class="bx bx-code-alt mb-4 h1 text-primary"></i>
                  <h5 class="font-size-15 text-primary text-uppercase">Code examples</h5>
                  <p class="mb-0">The code examples show how to approach some common functionalities and how to apply the secure coding principles!
                     We have secure code examples in different languages.</p>
                      <div class="mt-auto pt-3">
                        <button type="button" routerLink="/code-example/view" class="btn btn-primary btn-sm">Learn More</button>
                       </div>
                </div>
              </div>
            </div>
            <div class="col-md-3 d-flex align-items-stretch">
              <div class="card mt-4 maintenance-box">
                <div class="card-body d-flex flex-column">
                  <i class="bx bx-list-check mb-4 h1 text-primary"></i>
                  <h5 class="font-size-15 text-primary text-uppercase">Checklist</h5>
                  <p class="mb-0">Show different checklists that you can select for reference material. 
                    Click on the security control in the checklist to show the correlated knowledgebase item.</p>
                  <div class="mt-auto pt-3">
                    <button type="button" routerLink="/checklists/view" class="btn btn-primary btn-sm">Learn More</button>
                   </div>
                </div>
              </div>
            </div>
            <div class="col-md-3 d-flex align-items-stretch">
              <div class="card mt-4 maintenance-box">
                <div class="card-body d-flex flex-column">
                  <i class="bx bx-book mb-4 h1 text-primary"></i>
                  <h5 class="font-size-15 text-primary text-uppercase">Knowledge Base</h5>
                  <p class="mb-0">All the information which is correlated throughout the project can be found here! 
                    Need specific information on the spot then search through the Knowledge base items.</p>
                  <div class="mt-auto pt-3">
                    <button type="button" routerLink="/knowledgebase/view" class="btn btn-primary btn-sm">Learn More</button>
                   </div>
                </div>
              </div>
            </div>

            <div class="col-md-3 d-flex align-items-stretch">
                <div class="card mt-4 maintenance-box">
                  <div class="card-body d-flex flex-column">
                    <i class="bx bxs-flask mb-4 h1 text-primary"></i>
                    <h5 class="font-size-15 text-primary text-uppercase">
                      SKF Labs</h5>
                    <p class="mb-0">We created SKF Labs for Developers and security specialist to train their security testing skills. 
                      Also provided are write-ups for the labs to teach the correct testing methodology.</p>
                        <div class="mt-auto pt-3">
                          <button type="button" routerLink="/labs/view" class="btn btn-primary btn-sm">Learn More</button>
                         </div>
                      </div>
                </div>
              </div>
          </div>
          <!-- end row -->
        </div>
      </div>
    </div>
</div> <!-- container-fluid -->
