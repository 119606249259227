
export class AppSettings
{
   public static AUTH_TOKEN = sessionStorage.getItem('auth_token');
   public static USER = sessionStorage.getItem('user');
   public static SKIP_LOGIN = sessionStorage.getItem('skip_login');
   public static USER_PRIV = sessionStorage.getItem('privilege')
}


