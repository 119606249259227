<div class="container-fluid my-5 pt-5">
    <div class="container">
    <div class="row justify-content-center">
        <div class="col-md-8 col-xl-6">
          <div>
            <img src="assets/images/500.png" alt="" class="img-fluid">
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-12">
          <div class="text-center mb-5">
            <h4 class="text-uppercase">Internal Server Error</h4>
            <div class="mt-5 text-center">
              <a class="btn btn-primary" href="/">Back to Dashboard</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  